import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/model/data.model';
import { ApiService } from 'src/app/services/api.service';
import { ApiConstants } from 'src/app/api.constant';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { DatePipe } from '@angular/common';
import { FormBuilder ,FormGroup , FormControl , Validator, Validators} from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, } from "@angular/material-moment-adapter";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from "@angular/material/core";

export const MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: { dateInput: "D/MM/YYYY" },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM Y",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM Y",
  },
};

@Component({
  selector: 'app-eazypay-payment-option',
  templateUrl: './eazypay-payment-option.component.html',
  styleUrls: ['./eazypay-payment-option.component.scss'],
  providers: [ { provide: MAT_DATE_LOCALE, useValue: "en-GB" }, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], }, { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS }, ], encapsulation: ViewEncapsulation.None, })
export class EazypayPaymentOptionComponent implements OnInit {
  
  eazypayForm!: FormGroup;
  minDate = new Date();
  maxDate = new Date();
  getStatefromPincode:any;
  proceedJourney : boolean = false;
  easyPayEligibilityConfirmData: any;
  selectedGender : string = "Male";
  buttonDisabled: boolean = false;
  storeEazyPayResponse: boolean = false;
  getEazypayResponse : any;
  disableGenderButtons: boolean = false; // Add a boolean flag

  genders = [
    { value: 'Male', label: 'Male', icon: 'assets/images/eazypay-images/male-icon.png' },
    { value: 'Female', label: 'Female', icon: 'assets/images/eazypay-images/female-icon.png' }
  ];
  paymentDataObject: any;
  baseURl: string = "";
  urlEndPoint: string = "";
  hostPath: string = "";

  constructor(
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    private formbuilder: FormBuilder,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<EazypayPaymentOptionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    
    this.baseURl = location.pathname.split("/")[1];
    this.urlEndPoint = location.hash.split('/')[1];
    this.hostPath = location.host.split('/')[0];
    
    dialogRef.disableClose = true;
    this.createForm();

    if (this.baseURl !== "rbhealth-renewals" && this.urlEndPoint !== 'review'  ) {
      const storedData = sessionStorage.getItem("easyPayEligibilityConfirmData");
      const is_customer_eligible = storedData ? JSON.parse(storedData) : null;
      if(is_customer_eligible) {
        this.paymentDataObject = this.sharedDataService.getPaymentData();
        this.proceedJourney = true;
        this.storeEazyPayResponse = true;
        this.getLandingPagedata(is_customer_eligible);
      }
    }
     else if(this.baseURl === "rbhealth-renewals" && (this.urlEndPoint === 'review' || this.urlEndPoint === 'renewals-quotes' )  ) {

    const storedData = sessionStorage.getItem("easyPayEligibilityConfirmData");
    const is_customer_eligible = storedData ? JSON.parse(storedData) : null;
    if(is_customer_eligible) {
      this.paymentDataObject = this.sharedDataService.getPaymentData();
      this.proceedJourney = true;
      this.storeEazyPayResponse = true;
      this.getLandingPagedata(is_customer_eligible);
    }
    this.getPatchRenewalsData(data?.data)
      
    }
  }

  ngOnInit(): void {
    this.setCalenderRange();
  }

  getLandingPagedata(is_customer_eligible) {
    ["name","mobile","dob","pan","pincode"].forEach((fieldName) => { this.eazypayForm?.get(fieldName)?.disable(); });
    this.selectedGender = is_customer_eligible?.gender ;
    this.disableGenderButtons = true;
    this.easyPayEligibilityConfirmData = is_customer_eligible
  }

  getPatchRenewalsData(data) {
    
    ["mobile","dob","pincode"].forEach((fieldName) => { this.eazypayForm?.get(fieldName)?.disable(); });
    this.selectedGender = data?.gender === 1 ? "Male" : "Female";
    this.disableGenderButtons = true; // Set the flag to true to disable buttons
    this.eazypayForm.patchValue({
      name:data?.name,
      mobile:data?.mobile,
      dob:data?.dob,
      pincode:data?.pincode,
      // pan: data?.pan
    });

    if (  (this.hostPath === 'test.rbstaging.in' || this.hostPath === 'rbfinance.rbstaging.in' )) {
         // Handle PAN field for uat
        const panControl = this.eazypayForm.get('original_pan');
        if (panControl) {
          data?.pan ? (panControl.patchValue(data.pan), panControl.disable()) : panControl.enable();
        }
    } else {
        // Handle PAN field for production
        const panControl = this.eazypayForm.get('pan');
        if (panControl) {
          data?.pan ? (panControl.patchValue(data.pan), panControl.disable()) : panControl.enable();
        }
    }

    this.getPincode(data?.pincode)
  }

  /**Handles to get the state to pass in eazy pay create order its a mandatory field because**/
  getPincode(pincode) { 
    if (pincode.length >= 6 && !isNaN(pincode)) {
      this.apiService .getRequestedResponse(`${ ApiConstants?.PINCODE_VALIDATION }?pincode=${pincode}&provider=${""}`) .subscribe((response: any) => {
        if (response["status"]) {
          this.getPincodeData(pincode)
        } else if(response["insurers"]){
          this.getPincodeData(pincode)
        } else {
          if (response?.insurers == null) {
            this.eazypayForm.get("pincode")?.setErrors({ customError: response["message"] });
            this.eazypayForm.get("pincode")?.markAsTouched();
          }
        }
        });
    }
  }

  getPincodeData(pincode){
    this.apiService .getRequestedResponse(`${ApiConstants.GET_PINCODE}` + pincode) .subscribe((response: any) => {
      if (response && response.length > 0) {
        this.getStatefromPincode = response[0].state
      }
    });
  }

  /**Creates and initializes the Eazypay payment form using Angular's Reactive Forms **/
  createForm(): void {
    this.eazypayForm = this.formbuilder.group({
      // name: [ "New Aman", [ Validators.required, Validators.minLength(3), Validators.pattern(new RegExp("^[a-zA-Z]+(?: [a-zA-Z-]+)* ?$")), ], ],
      // mobile: [ "7291918967", [ Validators.required, Validators.pattern(new RegExp("^[6-9]{1}[0-9]{9}$")), Validators.minLength(10), Validators.maxLength(10), ], ],
      // dob: ["", [Validators.required, this.dateValidator.bind(this)]],
      // pan: [ "EYHPS0170L", [ Validators.required, Validators.pattern(new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]{1}$")), Validators.minLength(10), Validators.maxLength(10), ], ],
      // pincode: [ "20801", [ Validators.required, Validators.pattern("^[0-9]{6}$"), Validators.minLength(6), Validators.maxLength(6), ], ],
      
      name: [ "", [ Validators.required, Validators.minLength(3), Validators.pattern(new RegExp("^[a-zA-Z]+(?: [a-zA-Z-]+)* ?$")), ], ],
      mobile: [ "", [ Validators.required, Validators.pattern(new RegExp("^[6-9]{1}[0-9]{9}$")), Validators.minLength(10), Validators.maxLength(10), ], ],
      dob: ["", [Validators.required, this.dateValidator.bind(this)]],
      pan: [ "", [ Validators.required, Validators.pattern(new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]{1}$")), Validators.minLength(10), Validators.maxLength(10), ], ],
      pincode: [ "", [ Validators.required, Validators.pattern("^[0-9]{6}$"), Validators.minLength(6), Validators.maxLength(6), ], ],
      original_pan: [ "" ],

    });
  }

  /** Handles the input value for the PAN card field, ensuring it follows the alphanumeric format. **/
  panAlphaNumericValue(event: any) {
    let inputValue = event.target.value.toUpperCase();
    // Ensure only alphabets for first 5 characters
    let alphaPart = inputValue.substring(0, 5).replace(/[^A-Z]/g, "");
    // Ensure only numerics for next 4 characters
    let numericPart = inputValue.substring(5, 9).replace(/\D/g, "");
    // Ensure only alphabet at the end
    let lastChar = inputValue.substring(9, 10).replace(/[^A-Z]/g, "");
    // Concatenate the parts
    inputValue = alphaPart + numericPart + lastChar;
    this.eazypayForm.get("pan")?.setValue(inputValue);
  }

  /** Handles the selection of gender by the user. It can be either 'Male' or 'Female' **/
  selectGender(gender: string): void {
    this.selectedGender = gender;
  }

  /** Handles the submission of the Eazypay payment form. A boolean indicating whether the form is valid or not. **/
  submitEazyPayForm(isValid) {
    if (isValid) {
      this.buttonDisabled = true; // Disable the button
      let data: any = {
        full_name: this.eazypayForm.get("name")?.value,
        phone_no: this.eazypayForm.get("mobile")?.value,
        state: this.getStatefromPincode,
        PAN: this.eazypayForm.get("pan")?.value,
        gender: this.selectedGender,
        dob: this.datePipe.transform( this.eazypayForm.get("dob")?.value, "yyyy-MM-dd" ),
        pincode: this.eazypayForm.get("pincode")?.value,
        loan: {
         insurance_company: "Liberty General Insurance Ltd",
         insurance_type: "Health Insurance",
         policy_premium_amount: "5000",
         policy_tenure: "12", 
         policy_quotation_id: "1234dsd", 
         policy_type: "0",
         insurance_plan_name: "Other", 
         policy_start_date: "08-30-2024", 
         redirect_uri_on_success: "https://google.com/", 
         redirct_uri_on_failure: "https://google.com/",
         source: "test"
        },
        preCreditCheck: true,
        custom_data: {},
      };

      // Conditionally add the original_pan key and value
    if (this.hostPath === 'test.rbstaging.in' || this.hostPath === 'rbfinance.rbstaging.in' ) {
      data.test_pan = this.eazypayForm.get("original_pan")?.value?.toUpperCase();
    }

      this.easyPayEligibilityConfirmData = data;
      this.apiService.postRequestedResponse( `${ApiConstants.CREATE_EAZYPAY}`, data, "", false, true ) .subscribe((response: any) => {
        if (response) {
           if (response?.loan_offer?.status){
            sessionStorage.setItem("easyPayEligibilityConfirmData", JSON.stringify(data));
            this.storeEazyPayResponse = true;
            this.getEazypayResponse = response;
            setTimeout(() => {
              this.buttonDisabled = false; // Re-enable the button after 4 seconds
            }, 3000);
          } else {
            this.storeEazyPayResponse = false;
          }
            this.proceedJourney = true;
          } 
        });
        setTimeout(() => {
          this.buttonDisabled = false; // Re-enable the button after 4 seconds
        }, 3000);
    } else {
      for (const controlName in this.eazypayForm.controls) {
        if (this.eazypayForm.controls.hasOwnProperty(controlName)) {
          const control = this.eazypayForm.controls[controlName];
          if (control.invalid) {
            const element = document.querySelector(
              `[formControlName="${controlName}"]`
            ) as HTMLElement;
            if (element) {
              element.focus();
              break; // Stop after focusing on the first invalid field.
            }
          }
        }
      }
    }
  }

  updateBimaPayIds(plan_id, bima_paylead_id,months) {
    const data : any = {
      plan_id: plan_id ,
      bima_pay_id: bima_paylead_id,
      bima_payment_period :  months
    }
      this.apiService.postRequestedResponse(`${ApiConstants.UPDATE_EAZYPAY_ID}`, data)?.subscribe((response: any) => {
        if(response) {
          window.location.href = response?.shortURL;
        }    
      });
  }

  editEazyPayForm(){
    this.proceedJourney = false
  }


  /** Handles the user's decision to proceed or cancel the Eazypay payment journey.A boolean indicating the user's choice. True for proceeding, false for canceling. **/
  proceed(boolean) {
    const responseData = this.getEazypayResponse || this.sharedDataService.getPaymentData();
    if (boolean && this.baseURl!== "rbhealth-renewals") {
      this.dialogRef.close({ event: "close", data: responseData });
    } else if (boolean && this.baseURl === "rbhealth-renewals") {
      this.dialogRef.close({ event: "close", data: responseData });
    } else {
      this.dialogRef.close({ event: "close", data: false });
    }
  }

  /** Handles to close the popup**/
  closeDialog() {
    this.dialogRef.close({ event: "close", data: false });
  }

  /**  calender min max handling **/
  setCalenderRange() {
    const currentDate = new Date();
    this.maxDate = new Date(
      this.maxDate.setFullYear(currentDate.getFullYear() - 18)
    );
    this.minDate = new Date(
      this.minDate.setFullYear(currentDate.getFullYear() - 65)
    );
  }

  /** Validates the date input for the Eazypay payment form. An object with a 'validAgeFormat' property set to true if the control value is null, indicating an invalid date format.Returns null if the control value is not null, 
indicating a valid date format. **/
  dateValidator(control: FormControl) {
    if (control.value == null) {
      return { validAgeFormat: true };
    }
    return null;
  }
}
