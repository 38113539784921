import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModalService } from "../../services/common-modal.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SharedDataService } from "../../services/shared-data.service";
import { DialogData } from "../../model/data.model";
import { DateToAgePipe } from "../../pipes/date-to-age.pipe";
import { ApiService } from "../../services/api.service";
import { ApiConstants } from "src/app/api.constant";
@Component({
  selector: "app-plan-card",
  templateUrl: "./plan-card.component.html",
  styleUrls: ["./plan-card.component.scss"],
})
export class PlanCardComponent implements OnInit {
  @Output() healthCardDataEvent = new EventEmitter<any>(); // Create an output event

  dobAge: any;
  eldest_age: any | null;
  planData: any | undefined;
  healthPlan: any | undefined;
  bgColor: any;
  bgCardsColor: any;
  isCheckpath: boolean = true;
  baseURl : string = "";
  @Input() orderId = "";
  cardata: any;
  isSwap: boolean = false;
  pricebreakuppopup: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 31,
    widthObtained: "50%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "pricebreakuppopup",
  };
  paymentDataObject: any ;
  is_bimapay: boolean= false ;
  urlEndPoint: any;
  check_Upgrade_Renewals_Journey: any;

  constructor(
    private sharedDataService: SharedDataService,
    private commonModal: CommonModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    
    this.baseURl = location.pathname;
    this.urlEndPoint = location.hash.split('/')[1];
    this.paymentDataObject = this.sharedDataService.getPaymentData();

    this.sharedDataService.updatePlanId.subscribe((data) => {
      if (data && data != undefined) {
        this.isSwap = true;
        this.healthcardData(`?plan_id=${data}`,'','',false);
      }
    });

    }
    // // Retrieve payment data from local storage


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if ( params?.plan && params?.plan != undefined && params?.orderId == undefined ) {
        this.healthPlan = params["plan"];
        this.healthcardData(`?plan_id=${params?.plan}`,'','',true);
      } else if ( params?.plan && params?.plan != undefined && params?.orderId && params?.orderId != undefined && this.isSwap == false ) {
        this.healthPlan = params["plan"];
        this.healthcardData(`?order_id=${params?.orderId}`,'','',true);
        const token = sessionStorage.getItem("encrypted_token");
        if (token) {
          this.apiService .getRequestedResponse( `${ApiConstants.ENCRYPTED_TOKEN}?encrypted=${token}` ) .subscribe((res) => {
            if (this.baseURl === "/rbhealth-renewals/") {
              if (res?.is_journey === 'Upgrade') {
                this.check_Upgrade_Renewals_Journey = "Upgrade";
              } else if (!res?.is_journey) {
                this.check_Upgrade_Renewals_Journey = "Renewals";
              }
            }
            
          
              this.healthPlan = res?.plan_id;
              this.orderId = res?.order_id;
              if (res?.order_id && res?.order_id != undefined ) {
                this.healthcardData(`?order_id=${res?.order_id}`,'','',true);
              }

            });
        }

      } else {
        const token = sessionStorage.getItem("encrypted_token");
        if (token) {
          this.apiService .getRequestedResponse( `${ApiConstants.ENCRYPTED_TOKEN}?encrypted=${token}` ) .subscribe((res) => {
            
            if (this.baseURl === "/rbhealth-renewals/") {
              if (res?.is_journey === 'Upgrade') {
                this.check_Upgrade_Renewals_Journey = "Upgrade";
              } else if (!res?.is_journey) {
                this.check_Upgrade_Renewals_Journey = "Renewals";
              }
            }
            
          
              this.healthPlan = res?.plan_id;
              this.orderId = res?.order_id;
              if (res?.order_id && res?.order_id != undefined ) {
                this.healthcardData(`?order_id=${res?.order_id}`,'','',true);
              }

            });
        }
      }
    });
    this.sharedDataService.getdobForlife.subscribe((dob) => {
      this.dobAge = dob;
    });
  }



  /*** Fetches health card data based on the provided plan ID.**/
  healthcardData(healthPlan, selectedPaymentMonth, eazyPayLeadId, isBimaPayActive: boolean) {
    this.paymentDataObject = this.sharedDataService.getPaymentData();
    let url = `${ApiConstants.GET_HEALTHCARD_BY_ID}${healthPlan}`;
  
    const paymentMonth = selectedPaymentMonth === 'Full Payment' ? '' : selectedPaymentMonth || this.paymentDataObject?.selectedPaymentMonth;

    const bimaPayId = eazyPayLeadId || this.paymentDataObject?.eazyPayLeadId;
    // When the boolean is true, apply the condition
    if (isBimaPayActive && paymentMonth !== '' && bimaPayId) {
      url += `&easy_pay_month=${paymentMonth}&bima_pay_id=${bimaPayId}`;
    }
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        res[0].order_id = this.orderId;
        this.cardata = res[0];
        this.healthCardDataEvent.emit(this.cardata); // Emit the data to parent
      }
    });
  }
  


  viewPriceBreakupModal(data: any) {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.pricebreakuppopup["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.pricebreakuppopup["modalType"],
      width: this.pricebreakuppopup["widthObtained"],
      height: this.pricebreakuppopup["heightObtained"],
      classNameObtained: this.pricebreakuppopup["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        top: resTop,
        data: data,
        plan_id: this.healthPlan,
        is_renewals : this.check_Upgrade_Renewals_Journey  ,

      },
    };
    this.commonModal.openDialog(obj).subscribe((data: any) => {
      // if (data) {
      //   this.sharedDataService.getRetrievePlan(data["data"]);
      //   this.router.navigate(["customer-proposer", data["data"]]);
      // }
    });
  }
}
