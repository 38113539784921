import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../model/data.model";
import { ApiConstants } from "../../../api.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedDataService } from "src/app/services/shared-data.service";
@Component({
  selector: "app-price-breakup-popup",
  templateUrl: "./price-breakup-popup.component.html",
  styleUrls: ["./price-breakup-popup.component.scss"],
})
export class PriceBreakupPopupComponent implements OnInit {
  plan_id: any;
  viewBreakupData: any;
  constructor(
    private dialogRef: MatDialogRef<PriceBreakupPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService : SharedDataService,
  ) {
    this.plan_id = data["plan_id"];
    this.view_beakup(this.plan_id);
  }

  ngOnInit(): void {}
  closeDialog(): void {
    this.dialogRef.close();
  }
  view_beakup(plan_id) {
    this.apiService
      .getRequestedResponse(`${ApiConstants.VIEW_BREAKUP}?plan_id=${plan_id}`)
      .subscribe((res) => {
        this.viewBreakupData = res;
      });
  }

  getDisplayAmount(): number | null {
    if (this.data?.data?.installment_amount) {
      return this.data.data.installment_amount;
    } else if (this.viewBreakupData?.total_amount) {
      return this.viewBreakupData.total_amount;
    }
    return null; // Fallback if no value is available
  }
  
}
