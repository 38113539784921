export class ApiConstants {
  // create token
  public static CREATE_TOKEN: string = "/api/v2/credauth/create_token/";

  // credilio api for get /CATEGORIES
  public static CATEGORIES: string = "/api/v2/credauth/product_categories/";

  //top-picks wrap api
  public static WRAPPER_API: string = "/api/v2/credauth/wrapper_api/";

  //get-leads-status
  public static GET_STATUS: string = "/api/v2/get-leads-status/get_status/";

  //list leads ids
  public static List_LEADS: string = "/api/v2/transaction/lead_summary/";

  //count leads status
  public static LEADS_STAUTS: string =
    "/api/v2/credauth/credillio_leads_wrapper/";

  // by default plan show
  public static category_name = "gold";

  // for new rb health api
  public static GET_PLAN_FILTER: string =
    "/api/v4/health/plan_v4/get_plan_filter/";

  // for plan-listiing
  public static GET_PLAN_LIST: string = "/api/v4/health/plan_v4/";

  // for get order data
  public static GET_ORDER_DATA: string = "/api/v1/order/get_order/"; //'/api/v3/health/order/';

  // for get plan data by id
  public static GET_PLAN_DATA_BY_ID: string = "/api/v2/plan/retrieve_plan/";

  // for get pincode data
  public static GET_PINCODE: string = "/api/v2/pincode/get_pincode/?pincode=";

  // create order data
  // public static CREATE_ORDER: string = "/api/v1/order/create_order/";
  public static CREATE_ORDER: string = "/api/v3/health/order2/";

  // update create order data
  // public static UPDATE_CREATE_ORDER: string = "/api/v1/order/edit_order/";
  public static UPDATE_CREATE_ORDER: string = "/api/v3/health/order2/";

  public static GET_BASIC_AUTHENTICATION: string = `/api/v4/health/basic_authentication/get_details/`;

  public static VALIDATE_RESPONSES: string = `/api/v3/health/question_response/`;

  //get question details
  public static GET_QUESTION_DETAILS: string =
    "/api/v3/health/fetch_questions/";
  // "/api/v1/question/question_details/";

  // declaration submit
  public static DECLARATION_SUBMIT: string =
    "/api/v3/health/question_response/";

  // create otp
  public static CREATE_OTP: string = "/api/v2/otp/create_otp/";

  //  share_declaration_with_customer
  public static SHARE_DECLARATION_WITH_CUSTOMER: string =
    "/api/v1/customer/share_declaration_with_customer/";

  // get declaration response by order id
  public static GET_DECLARATION_RESPONSE_BY_ORDER_ID: string =
    "/api/v2/declaration/dec_response/?order=";

  // payment-gateway transaction url
  public static TRANSACTION_URL: string = "/api/v2/pg-gateway/transaction_url/";

  // send payment link
  public static SEND_PAYMENT_LINK: string = "/api/v1/order/send_payment_link/";

  //chek valid pincode
  // public static VALID_PINCODE: string =
  //   "/api/v4/health/plan_v4/pincode_validation/?pincode=";

  // get healthcard information by id
  public static GET_HEALTHCARD_BY_ID: string = "/api/v4/health/rbhealthcard/";

  public static ENCRYPTED_TOKEN: string = "/api/v2/token/decrypt/";

  // create adhaar otp
  public static Aadhaar_OTP = "/api/v4/health/basic_authentication/adhaar_otp/";

  // verify the aadhaar otp
  public static Aadhaar__VERIFICATION_OTP =
    "/api/v4/health/basic_authentication/adhaar_verify_otp/";

  // pa verification
  public static Pan_Verification =
    "/api/v4/health/basic_authentication/pan_authentication/";

  public static GENERATE_PROPOSAL_FORM: string =
    "/api/v1/order/generate_proposal_form/";

  public static SWAP_API: string =
    "/api/v3/health/member_validation/validate_members/";

  public static DYNAMIC_INVOICE: string =
    "/api/v2/customer_docs/dynamic_invoice/";
  public static BMI_VALIDATION: string =
    "/api/v4/health/validations/bmi_validation/";

  public static SHARE_BROCHURE: string = `/api/v4/health/brochure/`;

  public static SHARE_SCREENSHOT: string = `/api/v4/health/plan_v4/send_screenshot/`;

  public static VIEW_BREAKUP: string = `/api/v3/health/plan_breakup/view_breakup/`;

  public static DROPDOWN_PROPOSER: string = `/api/v3/health/dropdown/proposer/`;

  public static HOSPITAL_CITY_STATE: string = `/api/v3/health/hospital/get_hospital_city_state/`;

  public static HOSPITAL_LIST: string = `/api/v3/health/hospital/`;

  public static DOWNLOAD_HOSPITAL_LIST: string = `api/v3/health/hospital/download_hospital_list/`;

  public static SEND_HOSPITAL_DATA: string = `/api/v3/health/hospital/send_hospital_data/`;

  public static SHARE_NEW_TOKEN: string = `/api/v4/health/redirection/share_new_token/`;

  public static GET_RENEWAL_ORDER: string = `/api/v3/health/renewal_order/`;

  public static CREATE_RESPONSE_OLD_ORDER: string = `/api/v3/health/question_response/create_upgraded_response/`;

  public static HOSPITAL_PROVIDER_LISTING: string = `/api/v3/health/hospital/hospital_provider_listing/`;

  public static PINCODE_VALIDATION: string = `/api/v4/health/plan_v4/pincode_validation/`;

  public static BROCHURE_URL:string = `/api/v2/customer_docs/common_brochure_url/`

  public static CREATE_EAZYPAY:string = '/api/v4/bima-paybimapay/'

  public static UPDATE_EAZYPAY_ID:string = '/api/v4/bima-paybimapay/create_with_plan/'

  public static BimaPay_Order_Update:string = '/api/v4/bima-paybimapay/bima_pay_order_update/'

  public static UNMAPPED_BIMAPAY_ID:string = '/api/v4/bima-paybimapay/unmapped_bima_order/'


}
