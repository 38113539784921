<div id="top-features">
  <div class="top-features-heading">Top Features of the Plan</div>
  <div class="table-wrapper" *ngIf="getPlanType != 'Wellness'">
    <table
      class="features-table"
      cellspacing="”10″"
      cellpadding="”10″"
      *ngIf="getPlanType !== 'Wellness'"
    >
      <tbody class="table-body">
        <tr
          class="table-row"
          *ngFor="let row of featuresTableDataComboPlan; let i = index"
          [hidden]="!showAllRows && i >= 8"
        >
          <td
            class="table-data headcol"
            [ngClass]="{ 'odd-bg': i % 2 != 0 }"
            [innerHTML]="row.title"
          ></td>
          <td
            class="table-data long"
            *ngIf="!hideFeaturesTableData && getPlanType != 'Wellness'"
          >
            {{ row.value3 }}
          </td>
          <td
            class="table-data long"
            *ngIf="getPlanType != 'Wellness' && !hideFeaturesTableData"
          >
            {{ row.value2 }}
          </td>
          <ng-container>
            <td
              class="table-data long"
              *ngIf="hideFeaturesTableData || getPlanType != 'Wellness'"
            >
              {{ row.value1 }}
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <!-- <div class="tandc-text" (click)="HealthTandCPopup()">*T&C apply</div> -->
    <div *ngIf="getPlanType != 'Wellness'">
      <div class="tandc-text">*T&C apply</div>
      <div class="read-more" (click)="readMore()">
        View All Features
        <span *ngIf="!showAllRows">
          <img src="/assets/images/health-v2/icons/down-caret.svg" />
        </span>
        <span *ngIf="showAllRows">
          <img src="/assets/images/health-v2/icons/up-caret.svg" />
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="getPlanType === 'Wellness'">
    <table class="wellness-table">
      <tr
        *ngFor="let row of featuresTableDataWellnesPlan; let i = index"
        [ngClass]="{
          'first-row': i === 0,
          'last-row': i === featuresTableDataWellnesPlan.length - 1
        }"
      >
        <td [innerHTML]="row.title">{{ row.title }}</td>
        <td>{{ row.value4 }}</td>
      </tr>
    </table>
  </div>
</div>
