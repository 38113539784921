<div id="healthv2-quotelist-page">
  <div class="" #screenshotTarget>
    <div class="quotelist-card">
      <!-- Quotes planslist  html code [start]-->
      <div>
        <div class="row">
          <div class="col-sm-12">
            <div class="quotes-card">
              <form [formGroup]="chooseplanForm" (ngSubmit)="goToPage(chooseplanForm.valid)">
                <div class="row">
                  <div class="col-sm-12 col-md-4 input-pad">
                    <div class="input-heading">Date of Birth</div>
                    <div class="input-subheading">
                      Please enter the details of eldest member
                    </div>
                    <div class="form-label">
                      <mat-form-field appearance="outline" class="custom-mat-form-field custom-datpicker">
                        <input class="input-field-pad" matInput maxlength="10" formControlName="dob" appOnlyNumbers appDateFormat [matDatepicker]="dob" [min]="minDate" [max]="maxDate" placeholder="DD/MM/YYYY" (ngModelChange)="isDobselected()" />
                        <mat-datepicker-toggle matSuffix [for]="dob" class="calander-pos" [disabled]="isRenewable"></mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix [for]="dob" [disabled]="isRenewable"> <span class="age-field"> </span> </mat-datepicker-toggle>
                        <mat-datepicker #dob disabled="false"></mat-datepicker>
                        <div *ngIf="countAge"> <span class="years">{{ countAge }} Years</span> </div>
                        <mat-datepicker #dob></mat-datepicker>
                        <mat-error *ngIf=" chooseplanForm.get('dob')?.hasError('required') && !chooseplanForm .get('dob') ?.hasError('validAgeFormat') "> Please enter your D.O.B. </mat-error>
                        <mat-error *ngIf=" chooseplanForm .get('dob') ?.hasError('matDatepickerMin') "> Maximum allowed age is up to 65 Years. </mat-error>
                        <mat-error *ngIf=" chooseplanForm .get('dob') ?.hasError('matDatepickerMax') "> Must be 18 years or above. </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4 input-pad">
                    <div class="input-heading">Select Family Size</div>
                    <div class="input-subheading">
                      Please select whom do you want to cover
                    </div>
                    <div class="form-label">
                      <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="familysize" (selectionChange)="onFamilySizeChange($event)">
                          <!-- Bind selectedOption to mat-select -->
                          <mat-option *ngFor="let option of familySize" [value]="option.key">
                            {{ option.value }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf=" chooseplanForm .get('familysize') ?.hasError('required') "> Please select family size</mat-error> </mat-form-field>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4 input-pad">
                    <div class="input-heading">Pincode</div>
                    <div class="input-subheading">Please enter the pincode</div>
                    <div class="form-label">
                      <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input matInput formControlName="pincode" placeholder="Enter Pincode" [maxLength]="6" appOnlyNumbers (ngModelChange)="getPincode($event)" />

                        <mat-error *ngIf=" chooseplanForm.get('pincode')?.hasError('required') "> Please enter your pincode </mat-error>
                        <mat-error *ngIf=" chooseplanForm.get('pincode')?.hasError('maxLength') "> Pincode should contain Minimum 6 Number </mat-error>
                        <mat-error *ngIf=" chooseplanForm.get('pincode')?.hasError('pattern') "> Please enter the correct pincode </mat-error>
                        <mat-error *ngIf=" chooseplanForm.get('pincode')?.hasError('customError') "> {{ chooseplanForm.get('pincode')?.getError('customError') }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="!isRenewable">
                  <div class="col-sm-12 col-md-6 input-pad">
                    <mat-button-toggle-group class="custom-mat-toggle-btn" #userCategory="matButtonToggleGroup"
                      (change)="getPlanType($event)" formControlName="plantype">
                      <mat-button-toggle value="Wellness + Health Insurance" [disableRipple]="true">
                        RB Health
                      </mat-button-toggle>
                      <mat-button-toggle value="Wellness" [disableRipple]="true">
                        RB Wellness
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                    <!-- <div class="input-heading">Select Plan</div>
                    <div class="input-subheading">
                      Please choose your preferred plan
                    </div>
                    <mat-radio-group class="radio-button mr30" color="primary">
                      <mat-radio-button
                        class="custom-radio-button-clr custom-radio-button-margin col-3"
                        *ngFor="let item of planCombo"
                        [value]="item.key"
                        [checked]="item.isChecked"
                        (change)="getPlanType($event)"
                      >
                        <span class="radio-btn-image"></span>
                        <div class="content-align">
                          {{ item.value }}
                          <span class="hover-icon-container">
                            <img
                              src="/assets/images/health-v2/icons/hover-icon.svg"
                              class="hover-icon"
                            />
                            <div class="wellness-content">
                              {{
                                item.key == "Wellness"
                                  ? wellness_hover_text
                                  : combo_plan_hover_text
                              }}
                            </div>
                          </span>
                        </div>
                      </mat-radio-button>
                    </mat-radio-group> -->
                  </div>
                </div>
              </form>

              <div class="row pt10" >
                <div class="col-lg-4">
                  <div class="showing-payment-heading"> Select Payment Option </div>
                  <mat-radio-group [(ngModel)]="selectedPaymentOption">
                    <mat-radio-button class="custom-radio-button-clrs" *ngFor="let option of paymentOption" [value]="option.value" (click)="onPaymentOptionChange(option.value, $event)" >
                      <div class="content-align"> {{ option.key }} </div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-lg-4"  *ngIf="proceedWithEazyPay === true">
                  <div class="showing-payment-heading"> Select Payment Period </div>
                  <mat-radio-group [value]="selectedPaymentPeriod" (change)="getPaymentPeriod($event)">
                    <mat-radio-button class="custom-radio-button-clrs" *ngFor="let period of paymentPeriod" [value]="period.value">
                      <div class="content-align"> {{ period.key }} </div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              


              <div class="showing-plan-flex row pt10">
                <div class="col-lg-5">
                  <div class="showing-plan-heading">
                    Showing plans for {{ showfamilySize }}
                  </div>
                  <div class="showing-plan-subheading">
                    Showing plans for {{ age }} Years age
                  </div>
                </div>
                <div class="col-lg-7 no-pad">
                  <div class="btn-align">
                    <button class="download-btn" type="button" [ngClass]=" checkedIndexes.length > 0 || checkedColumnIndexes.length > 0 ? 'show-selected-plan-color' : 'show-selected-color' "
                     [disabled]=" checkedIndexes?.length === 0 && checkedColumnIndexes.length === 0 " (click)="toggleHideShow(); toggleHideShowColumn()" *ngIf="!isCustomerMobileModalOpen">
                      {{ isHidden || isColHidden ? "Show All" : "Show Selected" }}
                    </button>
                    <div class="" *ngIf="!isDigit">
                      <button mat-button [matMenuTriggerFor]="brochuredownload" class="download-brochure">
                        Download Brochure
                        <img src="/assets/images/health-v2/icons/down.svg" class="dropdown-img" />
                      </button>
                      <mat-menu #brochuredownload="matMenu">
                        <button mat-menu-item class="center-img" *ngFor="let urlName of brochureUrlObject | keyvalue" (click)=" downloadBroucher(urlName?.value, urlName?.key) ">
                          {{ urlName?.key }}
                          <img src="/assets/images/health-v2/icons/dwnload.svg" class="dwn" />
                        </button>
                        <!-- <button
                          mat-menu-item
                          class="center-img"
                          (click)="downloadBroucher('Wellness-Plan')"
                        >
                          Standalone Wellness Plan
                          <img
                            src="/assets/images/health-v2/icons/dwnload.svg"
                            class="dwn"
                          />
                        </button> -->
                      </mat-menu>
                    </div>

                    <!-- <div class="download-btn download-btn-color col-lg-4 col-4" (click)="downloadBroucher()"> <img src="/assets/images/health-v2/icons/download.svg" /> Download Brochure </div> -->
                    <div class="download-btn download-btn-color" (click)="fullCapture()"
                      *ngIf="!isCustomerMobileModalOpen">
                      <img src="/assets/images/health-v2/icons/share.svg" />
                      Share
                    </div>
                  </div>
                </div>
              </div>

              <!-- planlist  table code [start] -->
              <div class="table-wrapper" #screen>
                <table class="plan-table">
                  <thead>
                    <tr class="table-row" *ngIf="!isSpinner">
                      <th class="left-col suminsured-heading headcol table-header">
                        <div class="heading">Sum Insured</div>
                      </th>
                      <ng-container *ngFor=" let row of quotesList[quotesList.length - 1]?.value; let i = index " class="logo-container">
                        <th class="table-sticky-col plan table-header" *ngIf="row['name']" [ngClass]="{ logooverlay: blockInsurer?.length > 0 && blockInsurer?.indexOf(row['name']) != -1 }">
                          <div class="insurer">
                            <mat-checkbox [checked]="isColumnChecked(i)" *ngIf=" !isCustomerMobileModalOpen && row['name'] != 'Health Assure' " (change)="hideAndShowColumn($event, i)"></mat-checkbox>

                            <img [src]="transformLogoUrl(row['logo'])" class="insurer-img" />
                          </div>
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody class="table-body" *ngIf="!isSpinner">
                    <tr *ngFor="let row of quotesList; let i = index" [ngClass]="{ 'odd-row': i % 2 === 0, 'even-row': i % 2 !== 0 }" class="table-row-main">
                      <td class="left-col headcol table-data" [ngClass]="{ 'odd-row': i % 2 === 0, 'even-row': i % 2 !== 0 }" *ngIf="row.key != 'common_data'">
                        <div class="amount-container">
                          <div class="amt-radio">
                            <mat-checkbox [checked]="isRowChecked(i)" (change)="hideAndShow($event, i)" *ngIf="!isCustomerMobileModalOpen"></mat-checkbox>
                          </div>
                          <div class="plan-amount">
                            <div class="sum-amt">{{ row.key }}</div>
                            <div class="sum-insured">Sum Insured</div>
                          </div>
                        </div>
                      </td>

                      <ng-container *ngIf="row.key != 'common_data'">
                        <td class="table-sticky-col long table-data overlay-position" *ngFor="let planData of row?.value">
                          <div *ngIf="planData?.quotesText" class="web-only quote-not-available">
                            {{ planData?.quotesText }}
                          </div>
                          <div *ngIf="planData?.quotesText" class="mobile-only quote-not-available">
                            {{ planData?.quotesText }}
                            <!-- Quotes are not<br />
                            available in this<br />
                            pincode. -->
                          </div>
                          <div class="content" *ngIf="planData?.plan_id">
                            <div [ngClass]="isAllInvalid() ? 'btn_disabled' : (proceedWithEazyPay ? 'eazypay-amount-btn' : 'amount-btn')" 
                                 (click)="selectPlan(planData?.plan_id, planData?.insurer_name)">
                              ₹ {{ planData?.plan_amount }}
                              <span class="arrow-position"><img src="assets/images/health-v2/icons/arrow.svg" /></span>
                            </div>
                            <div *ngIf="proceedWithEazyPay" class="eazypay-month-text">for {{selectedPaymentPeriod}} Months</div>
                          </div>                      
                        </td>
                      </ng-container>
                    </tr>
                    <tr class="table-row" *ngIf="!isAllBlockInsurers">
                      <td class="table-sticky-col table-data"></td>
                      <ng-container *ngFor="let key of quotesFooter; let i = index">
                        <td class="table-sticky-col table-data valign" [ngClass]="{ overlay: blockInsurer?.length > 0 && blockInsurer?.indexOf(key['name']) != -1 }" *ngIf="key['name']">
                          <div class="footer-padding">
                            <div class="opd-text">
                              <span class="astrik">&ast;</span>
                              {{ key["isOpd"] }}
                            </div>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                  <tbody *ngIf="isSpinner">
                    <tr>
                      <td colspan="3" style=" text-align: center; position: relative; left: 50%; ">
                        <mat-spinner diameter="50"></mat-spinner>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div [ngClass]="selectedPaymentOption === 'Regular' ? 'gst-text' : 'eazypay-gst-text' ">{{ selectedPaymentOption === 'Regular' ? "Plan Amounts including GST*" : "*Quotes are approx. EMIs, doesn't include the advance payment of 25% of Total payable & Interest payable"}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Quotes planslist  html code [End]-->
    </div>
  </div>
</div>

<!-- Find your network hospital list html code [start] -->
<div id="network-hospital-card">
  <div class="image-container">
    <img class="web-img" src="assets/images/health-v2/banners/network_hospital_banner.jpg" alt="Desktop-banner" />
    <!-- <div class="find-hospital-heading">Find your near by Hospitals</div>
  <div class="check-here-btn" (click)="networkHospitals()">Check Here</div> -->
    <div class="clickable-area" (click)="handleClick()"></div>
  </div>
</div>

<div class="customer-waiting" *ngIf="submitFormLoader">
  <mat-spinner diameter="50"></mat-spinner>
  <p class="content">Please Wait...</p>
</div>