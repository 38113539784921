import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModalService } from "../../services/common-modal.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SharedDataService } from "../../services/shared-data.service";
import { DialogData } from "../../model/data.model";
import { DateToAgePipe } from "../../pipes/date-to-age.pipe";
import { ApiService } from "../../services/api.service";
import { ApiConstants } from "src/app/api.constant";
@Component({
  selector: "app-plan-card",
  templateUrl: "./plan-card.component.html",
  styleUrls: ["./plan-card.component.scss"],
})
export class PlanCardComponent implements OnInit {
  @Output() healthCardDataEvent = new EventEmitter<any>(); // Create an output event

  dobAge: any;
  eldest_age: any | null;
  planData: any | undefined;
  healthPlan: any | undefined;
  bgColor: any;
  bgCardsColor: any;
  isCheckpath: boolean = true;
  @Input() orderId = "";
  cardata: any;
  isSwap: boolean = false;
  pricebreakuppopup: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 31,
    widthObtained: "50%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "pricebreakuppopup",
  };
  paymentDataObject: any ;
  is_bimapay: boolean= false ;

  constructor(
    private sharedDataService: SharedDataService,
    private commonModal: CommonModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.sharedDataService.updatePlanId.subscribe((data) => {
      if (data && data != undefined) {
        this.isSwap = true;
        this.healthcardData(`?plan_id=${data}`);
      }
    });

    // // Retrieve payment data from local storage
    this.paymentDataObject = this.sharedDataService.getPaymentData();
    if (this.paymentDataObject) {
      this.is_bimapay = true;
    } else {
      this.is_bimapay = false;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (
        params?.plan &&
        params?.plan != undefined &&
        params?.orderId == undefined
      ) {
        this.healthPlan = params["plan"];
        this.healthcardData(`?plan_id=${params?.plan}`);
      } else if (
        params?.plan &&
        params?.plan != undefined &&
        params?.orderId &&
        params?.orderId != undefined &&
        this.isSwap == false
      ) {
        this.healthcardData(`?order_id=${params?.orderId}`);
      } else {
        const token = sessionStorage.getItem("encrypted_token");
        if (token) {
          this.apiService
            .getRequestedResponse(
              `${ApiConstants.ENCRYPTED_TOKEN}?encrypted=${token}`
            )
            .subscribe((res) => {
              this.healthPlan = res?.plan_id;
              this.orderId = res?.order_id;
              if (res?.order_id && res?.order_id != undefined) {
                this.healthcardData(`?order_id=${res?.order_id}`);
              }
            });
        }
      }
    });
    this.sharedDataService.getdobForlife.subscribe((dob) => {
      this.dobAge = dob;
    });
  }

  /*** Fetches health card data based on the provided plan ID.**/
  healthcardData(healthPlan) {
    let url = `${ApiConstants.GET_HEALTHCARD_BY_ID}${healthPlan}`;
    if (this.is_bimapay === true) {
      url += `&easy_pay_month=${this.paymentDataObject?.selectedPaymentMonth}&bima_pay_id=${this.paymentDataObject?.eazyPayLeadId}`;
    }
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        this.cardata = res[0];
        this.healthCardDataEvent.emit(this.cardata); // Emit the data to parent
        this.paymentDataObject = this.sharedDataService.getPaymentData();
        if (this.paymentDataObject) {
          this.is_bimapay = true;
        } else {
          this.is_bimapay = false;
        }
      }
    });
  }

  bimapaycardData(healthPlan, selectedPaymentMonth, eazyPayLeadId) {
    let url = `${ApiConstants.GET_HEALTHCARD_BY_ID}${healthPlan}&easy_pay_month=${selectedPaymentMonth}&bima_pay_id=${eazyPayLeadId}`;
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        this.cardata = res[0];
        this.healthCardDataEvent.emit(this.cardata); // Emit the data to parent
        this.paymentDataObject = this.sharedDataService.getPaymentData();
        if (this.paymentDataObject) {
          this.is_bimapay = true;
        } else {
          this.is_bimapay = false;
        }
      }
    });
  }

  viewPriceBreakupModal(data: any) {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.pricebreakuppopup["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.pricebreakuppopup["modalType"],
      width: this.pricebreakuppopup["widthObtained"],
      height: this.pricebreakuppopup["heightObtained"],
      classNameObtained: this.pricebreakuppopup["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        top: resTop,
        data: data,
        plan_id: this.healthPlan,
      },
    };
    this.commonModal.openDialog(obj).subscribe((data: any) => {
      // if (data) {
      //   this.sharedDataService.getRetrievePlan(data["data"]);
      //   this.router.navigate(["customer-proposer", data["data"]]);
      // }
    });
  }
}
