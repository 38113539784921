import { Component, Input, Inject, Optional, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
import { AngularDeviceInformationService } from "angular-device-information";
import { WindowRef } from "../../services/window.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-share-link-with-customer",
  templateUrl: "./share-link-with-customer.component.html",
  styleUrls: ["./share-link-with-customer.component.scss"],
})
export class ShareLinkWithCustomerComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ShareLinkWithCustomerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private deviceInformationService: AngularDeviceInformationService,
    private win: WindowRef
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  closeDialog() {
    // this.dialogRef.close({ event: 'close' });
    if (this.deviceInformationService.isMobile() && localStorage.getItem('is_app_user')=='true') {
      this.win.nativeWindow.ReactNativeWebView.postMessage("home");
    } else {
      window.location.href = `${environment["partnerPortalUrl"]}home/my-customer`;
    }
  }
}
